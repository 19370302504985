<template>
	<div class="autotrophy_goods">
		<e-table ref="tables" @query-Table-data="handleCurrentChange" :tableCols="autotrophyGoodsTableConf" v-on:resetSearch="resetSearch" v-on:searchParamsChange="searchParamsChange" :customSearchList="searchFormList" :showOperation="true" :dataOrigin="dataOrigin">
			<template #customOperation>
				<el-form-item label="排期" style="float: left;position: absolute;top: 43px;">
					<el-date-picker
							style="width:150px"
							@change="batchDataSelect($event)"
							v-model="pqtime"
							type="date"
							placeholder="批量排期">
					</el-date-picker>
				</el-form-item>
				<el-upload
						class="avatar-uploader mr10"
						:action=uploadUrl
						:data="data"
						:show-file-list="false"
						:on-success="handleXslSuccess"
						:before-upload="beforeAvatarUpload">
					<el-button>导入文档</el-button>
				</el-upload>
				<el-col :span="3">

					<a href="/批量导入商品信息.xlsx" target="_blank">
						<el-button>下载模板</el-button>
					</a>
					<el-button @click="$refs.dialogRef.handleOpen()">添加商品</el-button>
				</el-col>
			</template>
			<template #title="scope">
				<el-link class="lh_12" type="primary" :href="scope.rowData.item_url" target="_blank">{{ scope.rowData.title }}</el-link>
			</template>
			<template #pict_url="scope">
				<el-popover
						trigger="hover">
					<img height="300" width="300" :src="scope.rowData.pict_url" alt="">
					<img slot="reference" height="80" width="80" :src="scope.rowData.pict_url" alt="">
				</el-popover>
			</template>
			<template #content="scope">
				<div class="html-content" v-html="scope.rowData.content"></div>
			</template>
			<template #zb_price="scope">
				<span>{{scope.rowData.zb_price}} - {{scope.rowData.zb_e_price}}</span>
			</template>
			<template #skuItem="scope">
				<el-input :rows="3" type="textarea" placeholder="" :value="scope.rowData.skuItem"></el-input>
			</template>
			<template #operation="scope">
				<div></div>
				<el-date-picker
						style="width:150px"
						@change="dataSelect($event,scope.rowData)"
						class="mt10"
						v-model="scope.rowData.zbtime"
						type="date"
						placeholder="选择排期">
				</el-date-picker>
				<div class="cur_p" @click="sj(scope.rowData.id)" v-if="scope.rowData.is_on == 2">上架</div>
				<div class="cur_p"  @click="xj(scope.rowData.id)" v-if="scope.rowData.is_on == 1">下架</div>
				<div class="cur_p"  @click="del(scope.rowData.id)">删除</div>
				<div class="cur_p" @click="$router.push({path:'/autotrophyGoodsDetail',query:{id:scope.rowData.id}})">编辑</div>
			</template>
		</e-table>
		<e-dialog title="新增商品" ref="dialogRef" width="1200px" v-on:confirm="confirm">
			<div class="">
				<div class="base_info flex_r">
					<el-upload
					class="avatar-uploader mr10"
					:action="imgUploadUrl"
					:data="data"
					:show-file-list="false"
					:on-success="handleAvatarSuccess"
					:before-upload="beforeAvatarUpload">
					<img v-if="pict_url" :src="pict_url" class="avatar">
					<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
					<el-form :inline="true" :model="form" label-width="auto">
						<el-form-item label="商品链接" class="w100">
							<div class="flex_r">
								<el-input style="width: 600px" placeholder="请输入商品链接" v-model="url"></el-input>
								<el-button class="ml20" type="danger" @click="sync">同步信息</el-button>
							</div>
						</el-form-item>
						<el-form-item label="商品名称">
							<el-input v-model="form.item_url" placeholder="请输入商品链接"></el-input>
						</el-form-item>
						<el-form-item label="意向佣金">
							<el-input v-model="form.yx_rate" placeholder="请输入意向佣金"></el-input>
						</el-form-item>
						<el-form-item label="商品类目">
							<el-select v-model="form.cat" placeholder="商品分类">
								<el-option :label="cate.name" :value="cate.id"  v-for="cate in category" :key="cate.index"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="店铺名称">
							<el-input v-model="form.store_name" placeholder="请输入店铺名称"></el-input>
						</el-form-item>
						<el-form-item label="店铺地址">
							<el-input v-model="form.store_url" placeholder="请输入店铺地址"></el-input>
						</el-form-item>
						<el-form-item label="在售价格">
							<div class="flex_r f_ai_c">
								<el-input v-model="form.s_price" class="w_200" placeholder="最低在售价"></el-input>
									<span class="ml10 mr10">——</span>
								<el-input v-model="form.e_price" class="w_200" placeholder="最高在售价"></el-input>
							</div>
						</el-form-item>
						<el-form-item label="直播价格">
							<div class="flex_r f_ai_c">
								<el-input v-model="form.zb_price" class="w_200" placeholder="最低直播价"></el-input>
								<span class="ml10 mr10">——</span>
								<el-input v-model="form.zb_e_price" class="w_200" placeholder="最高直播价"></el-input>
							</div>
						</el-form-item>
					</el-form>
				</div>
				<div class="mt20 mb20">
					<span>商品规格：</span>
					<el-tag class="mr10" closable effect="plain" @close="handleClose(index)" v-for="(sku,index) in skus" :key="sku.index">{{sku.attr_name}}-{{sku.price}}</el-tag>
<!--					<el-tag @click="$refs.dialogConfRef.handleOpen()" class="cur_p" effect="plain" type='danger'>添加自定义规格</el-tag>-->
				</div>
				<ul class="flex_r">
					<li class="flex_r f_ai_c mr30">
						<span class="w_75">库存：</span>
						<el-input placeholder="库存" v-model="form.sku"></el-input>
					</li>
<!--					<li class="flex_r f_ai_c">-->
<!--						<span class="w_75">保质期：</span>-->
<!--						<el-input placeholder="保质期" v-model="form.bzq">-->
<!--							<template slot="append">-->
<!--								<el-dropdown>-->
<!--									<span class="el-dropdown-link">-->
<!--										年<i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>-->
<!--									</span>-->
<!--									<el-dropdown-menu slot="dropdown">-->
<!--										<el-dropdown-item>年</el-dropdown-item>-->
<!--										<el-dropdown-item>月</el-dropdown-item>-->
<!--										<el-dropdown-item>日</el-dropdown-item>-->
<!--									</el-dropdown-menu>-->
<!--								</el-dropdown>-->
<!--							</template>-->
<!--						</el-input>-->
<!--					</li>-->
				</ul>
				<div class="fw_b mt30 mb20">直播信息：</div>
				<div class="flex_r f_ai_c">
					<span class="w_75">优惠方式：</span>
					<el-select v-model="form.yh_type" placeholder="优惠方式" @change="yhSelect">
						<el-option label="无" value="1"></el-option>
						<el-option label="拍下立减" value="2"></el-option>
						<el-option label="详情页领券" value="3"></el-option>
						<el-option label="联系客服领取" value="4"></el-option>
						<el-option label="其他优惠" value="5"></el-option>
						<el-option label="直接改价" value="6"></el-option>
					</el-select>
				</div>
				<div class="flex_r f_ai_c mt30" v-if="yh">
					<span class="w_75">优惠金额：</span>
					<el-input v-model="form.yh" placeholder="优惠金额"></el-input>
				</div>
				<div class="flex_r mt30 mb30">
					<span class="w_75">商品卖点：</span>
					<editor ref="editor" />
				</div>
			</div>
		</e-dialog>
		<e-dialog title="新增规格" ref="dialogConfRef" v-on:confirm="skuConfirm">
			<el-form label-width="80px">
				<el-form-item label="规格">
					<el-input v-model="skuItem.name"></el-input>
				</el-form-item>
				<el-form-item label="价格">
					<el-input v-model="skuItem.price"></el-input>
				</el-form-item>
			</el-form>
		</e-dialog>
	</div>
</template>

<script>
import {autotrophyGoodsTableConf, searchFormList} from '@data/autotrophyGoods'
import Editor from '../../components/editor.vue';
export default {
	components: { 
		Editor
	},
	name: 'AutotrophyGoods',
	data() {
		return {
			autotrophyGoodsTableConf,
			searchFormList,
			dataOrigin: {
				data: []
			},
			imageUrl: '',
			total: 1,
			currentPage: 1,
			form: {},
			condition: {},
			skus:[],
			yh: false,
			uploadUrl: '',
			data: {},
			category: [],
			url: '',
			skuItem: {},
			imgUploadUrl: '',
			pict_url: '',
			pqtime: '',
			gxGoods: []
		}
	},
	methods: {
		batchDataSelect(e){

			if(e == '' || e == null || e == undefined){
				this.$message.error("请选择排期时间");
				return
			}

			let goodsData = this.$refs.tables.selectionData()
			let len = goodsData.length
			if(len == 0){
				this.gxGoods = []
			}
			for (let i = 0;i<len;i++){
				this.gxGoods.push(goodsData[i].id)
			}

			let that = this
			if(this.gxGoods.length < 1){
				that.$message.error('请勾选商品');
				return
			}
			let goodsStr = this.gxGoods.toString()
			this.post('?server=live.anchor.goods.zb.batch.push.handle.post',{
				goods_id:goodsStr,
				date:e
			}).
			then(function (response) {
				//这里只会在接口是成功状态返回
				//联网成功的回调
				that.$message.success(response.data.msg);
				that.$refs.tables.clearSelectionData()
				that.GetLists()
				// loading.close()
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
			});
		},
		dropDownClick(){},
		handleCurrentChange(data){
			this.$refs.tables.loading = true
			this.currentPage = data.currentPage
			this.GetLists()
		},
		dataSelect (e,value){
			console.log(value)
			let that = this
			if(e == '' || e == null || e == undefined){
				this.$message.error("请选择排期时间");
				return
			}
			this.post('?server=live.anchor.schedule.push.handle.post',{goods_id:value.id,date:e}).
			then(function (response) {
				//这里只会在接口是成功状态返回
				//联网成功的回调
				that.$message.success(response.data.msg);
				that.$refs.tables.loading = true
				that.GetLists()
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
			});
		},
		handleClose(index){
			this.skus.splice(index, 1);
		},
		sj(id){
			let that = this

			this.$confirm('是否上架该商品?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				that.post('?server=live.anchor.goods.handle',{
					id:id}).
				then(function () {
					//这里只会在接口是成功状态返回
					//联网成功的回调
					that.$message({
						type: 'success',
						message: '上架成功!'
					});
					that.$refs.tables.loading = true
					that.GetLists()
				}).catch(function (error) {
					//这里只会在接口是失败状态返回，不需要去处理错误提示
					console.log(error);
				});
			}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消'
				});
			});
		},
		xj(id){
			let that = this

			this.$confirm('是否下架该商品?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				that.post('?server=live.anchor.goods.handle&method=back',{
					id:id}).
				then(function () {
					//这里只会在接口是成功状态返回
					//联网成功的回调
					that.$message({
						type: 'success',
						message: '下架成功!'
					});
					that.$refs.tables.loading = true
					that.GetLists()
				}).catch(function (error) {
					//这里只会在接口是失败状态返回，不需要去处理错误提示
					console.log(error);
				});
			}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消'
				});
			});
		},
		del(id){
			let that = this

			this.$confirm('是否删除该商品?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				that.post('?server=live.anchor.goods.handle&method=del',{
					id:id}).
				then(function () {
					//这里只会在接口是成功状态返回
					//联网成功的回调
					that.$message({
						type: 'success',
						message: '删除成功!'
					});
					that.$refs.tables.loading = true
					that.GetLists()
				}).catch(function (error) {
					//这里只会在接口是失败状态返回，不需要去处理错误提示
					console.log(error);
				});
			}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消'
				});
			});
		},
		skuConfirm(){
			if (this.skuItem.name == undefined || this.skuItem.name == '') {
				this.$message.error('请输入规格名称');

				return false;
			}
			if (this.skuItem.price == undefined || this.skuItem.price == '') {
				this.$message.error('请输入价格');

				return false;
			}
			this.skus.push(this.skuItem)
			this.$refs.dialogConfRef.handleClose()
		},
		confirm(){
			let that = this
			if(JSON.stringify(this.form) == '{}'){
				that.$message.error('请先同步商品信息');
				return false
			}
			if(this.form.yx_rate == undefined || this.form.yx_rate == ''){
				that.$message.error('请输入佣金');
				return false
			}
			if(this.form.title == undefined || this.form.title == ''){
				that.$message.error('请输入标题');
				return false
			}
			if(this.form.cat == undefined || this.form.cat == ''){
				that.$message.error('请选择商品类目');
				return false
			}
			if(this.form.s_price == undefined || this.form.s_price == ''){
				that.$message.error('请输入最低在售价格');
				return false
			}
			if(this.form.e_price == undefined || this.form.e_price == ''){
				that.$message.error('请输入最高在售价格');
				return false
			}
			if(this.form.zb_price == undefined || this.form.zb_price == ''){
				that.$message.error('请输入最低直播价');
				return false
			}
			if(this.form.zb_e_price == undefined || this.form.zb_e_price == ''){
				that.$message.error('请输入最高直播价');
				return false
			}
			if(this.$refs.editor.content == undefined || this.$refs.editor.content == ''){
				that.$message.error('请输入卖点');
				return false
			}
			// let loading = this.$loadings.service({
			// 	'text': "请求中",
			// 	background: 'rgba(28,28,28,0.7)',
			// })
			this.post('?server=live.anchor.goods.push.handle.post',{
				yx_rate:this.form.yx_rate,
				title:this.form.title,
				content:this.$refs.editor.content,
				item_url:this.form.item_url,
				yh_type:this.form.yh_type,
				nickname: this.form.store_name,
				num_iid: this.form.num_iid,
				seller: this.form.seller,
				store_url: this.form.store_url,
				user_type: this.form.user_type,
				skus: this.skus,
				s_price: this.form.s_price,
				e_price: this.form.e_price,
				zb_price: this.form.zb_price,
				zb_e_price: this.form.zb_e_price,
				sku: this.form.sku,
				cid: this.form.cat,
				yh: this.form.yh,
				pict_url:this.form.origin_url}).
			then(function (response) {
				//这里只会在接口是成功状态返回
				//联网成功的回调
				that.$message.success(response.data.msg);
				// loading.close()
				that.GetLists()
				that.form = {}
				that.$refs.dialogRef.handleClose()
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
			});
		},
		yhSelect(){
			let that = this
			switch (that.form.yh_type) {
				case '1':
					that.yh = false
					break;
				case '2':
					that.yh = true
					break;
				case '4':
					that.yh = true
					break;
				case '3':
					that.yh = true
					break;
				default :
					that.yh = false
					break;
			}

		},
		resetSearch(){
			this.$refs.tables.loading = true
			this.form = {}
			this.condition = {}
			this.currentPage = 1
			this.GetLists()
		},
		searchParamsChange(e) {
			this.$refs.tables.loading = true
			this.condition = e.condition
			this.currentPage = 1
			this.GetLists()
		},
		handleClick() {},
		handleXslSuccess(response){
			if (response.data.code === 4003) {
				this.$message.error('您没有权限操作');

				return false;
			}
			if(response.data.code == 0){
				this.$message.error(response.data.msg);
				return
			}
			this.$message.success(response.data.msg);
		},
		handleAvatarSuccess(response){
			if (response.code === 4003) {
				this.$message.error('您没有权限操作');

				return false;
			}
			if(response.code == 0){
				this.$message.error(response.msg);
				return
			}
			this.$message.success(response.msg);
			this.pict_url = response.data.url
			this.form.origin_url = response.data.origin_url
		},
		beforeAvatarUpload(file) {
			const isLt2M = file.size / 1024 / 1024 < 2;

			if (!isLt2M) {
			this.$message.error('上传图片大小不能超过 2MB!');
			}
			return isLt2M;
		},
		sync(){
			let that = this
			let url = that.url
			if(url == '' || url == undefined){
				that.$message.error('输入商品链接');
				return
			}
			let loading = this.$loadings.service({
				'text': "请求中",
				background: 'rgba(28,28,28,0.7)',
			})

			this.get('?server=live.seller.goods.sku.item.info.get',{goods_link: url, num_iid: url}).
			then(function (response) {
				//这里只会在接口是成功状态返回
				//联网成功的回调pict_url
				that.$message.success(response.data.msg);
				that.form = response.data.data
				that.form.title = response.data.data.title
				that.form.pict_url = response.data.data.pic_url
				that.pict_url = response.data.data.pic_url
				that.form.item_url = response.data.data.detail_url
				that.form.nickname = response.data.data.nick
				that.form.store_name = response.data.data.nick
				that.form.local = response.data.data.location
				that.form.num_iid = response.data.data.num_iid
				that.form.seller = response.data.data.seller_id
				that.form.user_type = response.data.data.tmall ? 1 : 0
				that.form.volume = response.data.data.sales
				that.form.origin_url = response.data.data.pic_url
				that.form.store_url = response.data.data.seller_info.zhuy

				that.skus = [];
				let len = response.data.sku.length
				// console.log(len)
				for(let i=0;i<len;i++){
					// console.log(response.data.data[i])
					that.skus.push(response.data.sku[i])
				}
				console.log(that.skus)
				loading.close()
				// console.log(that.form)
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				loading.close()
				that.$message.error('请求超时');
				console.log(error);
			});
		},
		GetCategoryLists(){
			let that = this
			this.category = []
			this.get('?server=goods.category.get',{}).
			then(function (response) {
				//这里只会在接口是成功状态返回
				//联网成功的回调
				// console.log(response)
				that.category = response.data
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
			});
		},
		GetLists(){
			let that = this
			this.dataOrigin.data = []
			that.$refs.tables && that.$refs.tables.initData()
			this.currentPage = that.$refs.tables ? that.$refs.tables.currentPage : 1
			let formData = this.$refs.tables.condition
			// console.log(that.dataOrigin)
			this.get('?server=live.anchor.goods.lists.get',{
				page:this.currentPage,
				query: formData.title,
				shop_name: formData.shop_name,
				status: formData.status
			}).
			then(function (response) {
				//这里只会在接口是成功状态返回
				//联网成功的回调
				// console.log(response.data.total)
				let len = response.data.data.length
				// console.log(len)
				for(let i=0;i<len;i++){
					// console.log(response.data.data[i])
					that.dataOrigin.data.push(response.data.data[i])
				}
				that.total = response.data.total
				that.$refs.tables.total = response.data.total
				that.$refs.tables.loading = false
				that.$refs.tables.initData()
				// that.dataOrigin.data = response.data.data
				// console.log(that.total)
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
			});
		},
	},
	created() {
		this.$nextTick(() => {
			this.$refs.tables.loading = true
			let token = localStorage.getItem('token')
			this.data = {"auth_token": token};
			this.uploadUrl = this.appConfig.api_request_url+'?server=live.anchor.goods.xsl.upload.file'
			this.imgUploadUrl = this.appConfig.api_request_url+'?server=upload.file'
			this.GetLists()
			this.GetCategoryLists()
			this.$refs.tables.showSelectionCol = true
		});
	}
}
</script>

<style >
	.autotrophy_goods .tb .operation .el-form {
		height: 76px;
	}
</style>

<style lang="scss">
.avatar-uploader .el-upload {
	border: 1px dashed #ccc;
	border-radius: 6px;
	cursor: pointer;
	position: relative;
	overflow: hidden;
}
.avatar-uploader .el-upload:hover {
	border-color: #409EFF;
}
.avatar-uploader-icon {
	font-size: 28px;
	color: #8c939d;
	width: 128px;
	height: 128px;
	line-height: 128px;
	text-align: center;
}
.avatar {
	width: 128px;
	height: 128px;
	display: block;
}
</style>